import { Box, Grid, Paper, Typography } from "@mui/material";
import { ReactComponent as LockIcon } from "../assets/LockIcon.svg";
import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Circle as CircleIcon,
  Star as StarIcon,
  StarBorder as StarBorderIcon,
} from "@mui/icons-material";
import useDisplaySize from "../hooks/useDisplaySize";
import { Tag } from "../utils/interfaces";
import { useEffect, useState } from "react";

const tagColors = {
  "Pack": "#D9D9D9",
  "Type": "#2d59d5",
  "Color": "#b01036",
}

export function SkinPackCard(this: any, props: any) {
  const {
    skinPack,
    isSelected = false,
    onClick = () => {},
    publicPack = true,
    order = null,
    updateOrder = () => {},
    tags = [],
    editor = false,
    favorites = [],
    toggleFavorite = () => {},
  } = props;

  const matches = useDisplaySize();

  const [hover, setHover] = useState(false);

  function getTags(){  
    const typeOrder = ["Pack", "Type", "Color"];

    const selectedTagsMap = new Map();

  // Iterate over the types in the specified order
  typeOrder.forEach((type) => {
    // Find the tags with the current type
    const tagsOfType = tags.filter((tag: Tag) => tag.type === type);

    // Add the first tag of the specified type to the map
    if (tagsOfType.length > 0) {
      selectedTagsMap.set(type, tagsOfType[0]);
    }
  });

    return Array.from(selectedTagsMap.values());
  }

  const selectedTags = getTags();

  return (
    <Grid item xs={3} key={skinPack._id}>
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          p: matches["md"] ? 0.5 : 1,
          flexDirection: "column",
          justifyContent: "space-between",
          cursor: "pointer",
          height: "100%",
          background: isSelected ? "#A63C46" : "",
          pb: !matches["md"] && selectedTags && selectedTags.length > 0 ? 0 : matches["md"] ? 0.5 : 1,
        }}
        onClick={() => onClick(skinPack)}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Box
            sx={{
              position: "relative",
              background: "black",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {!editor && (hover || favorites.includes(skinPack._id)) && (
              <Box
                sx={{
                  position: "absolute",
                  top: "4%",
                  left: "2%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "9%",
                  height: "18%",
                  zIndex: 10,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFavorite(skinPack._id);
                }}
              >
                {favorites.includes(skinPack._id) && <StarIcon sx={{ color: "#FFD700", width: "100%", height: "100%" }} />}
                {!favorites.includes(skinPack._id) && <StarBorderIcon sx={{ color: "#FFD700", width: "100%", height: "100%" }} />}
              </Box>
            )}

            {skinPack?.displayImage?.url.split(".").pop() === "webm" ? (
              <video
                disableRemotePlayback
                disablePictureInPicture
                src={skinPack?.displayImage?.cdnUrl}
                style={{ width: "100%" }}
                loop
                muted
                autoPlay
                onError={({ currentTarget }) => {
                  if (currentTarget.getAttribute("data-error-handled")) return;

                  currentTarget.setAttribute("data-error-handled", "true");
                  currentTarget.onerror = null;
                  currentTarget.src = `https://placehold.co/600x300/000000/FFFFFF/png?text=${skinPack.name}&font=montserrat`;
                }}
              />
            ) : (
              <img
                src={skinPack?.displayImage?.cdnUrl}
                alt={skinPack?.displayImage?.name || ""}
                style={{ width: "100%" }}
                onError={({ currentTarget }) => {
                  if (currentTarget.getAttribute("data-error-handled")) return;

                  currentTarget.setAttribute("data-error-handled", "true");
                  currentTarget.onerror = null;
                  currentTarget.src = `https://placehold.co/600x300/000000/FFFFFF/png?text=${skinPack.name}&font=montserrat`;
                }}
              />
            )}
            {order && order.order !== null && (
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <ArrowBackIosNewIcon
                  sx={{
                    cursor: "pointer",
                    height: "100%",
                    width: "25%",
                    color: "#A63C46",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    updateOrder(order, "down");
                  }}
                />
                <ArrowForwardIosIcon
                  sx={{
                    cursor: "pointer",
                    height: "100%",
                    width: "25%",
                    color: "#A63C46",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    updateOrder(order, "up");
                  }}
                />
              </Box>
            )}
            {!publicPack && (
              <LockIcon
                height={"15%"}
                style={{ position: "absolute", top: "5%", right: "3%" }}
              />
            )}
          </Box>

          {!matches['md'] && selectedTags && selectedTags.length > 0 && (
            <Box sx={{display: 'flex'}}>
              {selectedTags.map((tag: Tag) => (
                <Box
                  key={tag._id}
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    background: "#191a1d",
                    borderRadius: 1,
                    px: 0.6,
                    py: 0.3,
                    m: 0.5,
                    ml: 0,
                  }}
                >
                  <CircleIcon sx={{width: '16px', height: '16px', mr: 0.5, color: tagColors[tag.type]}} />
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "0.8rem",
                      textAlign: "center",
                      overflow: "ellipsis",
                      pr: 0.4,
                    }}
                    noWrap
                  >
                    {tag.type === "Color" && tags.filter((t: Tag) => t.type === "Color").length > 1 ? "Variations" : tag.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Paper>
    </Grid>
  );
}
